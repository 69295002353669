<template>
     <v-container fluid class="ma-0 pa-0 clear-background" >
            <v-layout row wrap>
              <div class="max-width-1200 ma-auto">
              <v-layout row wrap ma-12 :class="{'pa-6': $vuetify.breakpoint.smAndUp}">
                  <v-flex xs12 sm6>
                            <p class="venera font-18 primary-color mb-0">{{$ml.get('since')}} 2010</p>
                            <p class="antenna font-34 primary-color"> {{ $ml.get('process_title') }} </p>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <p class="antenna font-34 primary-color line-height-38" >{{ $ml.get('process_subtitle') }} </p>
                            <p class="avenir font-18 primary-color mb-0">  {{ $ml.get('process_text') }}</p>
                        </v-flex>
              </v-layout>
              </div>
              </v-layout>


              <div class="my-12">

                  <div class="max-width-1200 ma-auto">
                  <v-layout row wrap  :class="{'px-12 mx-12': $vuetify.breakpoint.smAndUp}">
                    <v-flex sm6 xs12>
                      <v-layout align-center style="height:100%">
                        <v-img class="blue-overlay" :src="evaluate" :lazy-src="evaluate"/>
                      </v-layout>
                    </v-flex>
                    <v-flex sm6 xs12 class="white-background">
                      <div :class="{'ma-12': $vuetify.breakpoint.smAndUp, 'ma-6': $vuetify.breakpoint.xsOnly}">
                        <p class="venera font-19 primary-color">01</p>
                        <h3 class="antenna font-28 third-color my-4"> {{ $ml.get('evaluate_title') }} </h3>
                        <p class="avenir font-16 primary-color">{{ $ml.get('evaluate_subtitle') }} -</p>
                        <p class="avenir font-16 primary-color">{{ $ml.get('evaluate_text') }}</p>
                      </div>
                    </v-flex>
                  </v-layout>
                  </div>

                <div class="white-background">
                  <div class="max-width-1200 ma-auto">
                  <v-layout row wrap :class="{'px-12 mx-12': $vuetify.breakpoint.smAndUp, 'reverse-class': $vuetify.breakpoint.xsOnly}">
                      <v-flex sm6 xs12 :class="{'clear-background': $vuetify.breakpoint.smAndUp, 'white-background': $vuetify.breakpoint.xsOnly}">
                      <div :class="{'ma-12': $vuetify.breakpoint.smAndUp, 'ma-6': $vuetify.breakpoint.xsOnly}">
                        <p class="venera font-19 primary-color">02</p>
                        <h3 class="antenna font-28 third-color my-4"> {{ $ml.get('collaboration_title') }} </h3>
                        <p class="avenir font-16 primary-color">{{ $ml.get('collaboration_subtitle') }} -</p>
                        <p class="avenir font-16 primary-color">{{ $ml.get('collaboration_text') }}</p>
                      </div>
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-layout align-center style="height:100%">
                        <v-img class="blue-overlay" :src="collaboration" :lazy-src="collaboration"/>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  </div>
                  </div>

                   <div class="max-width-1200 ma-auto">
                  <v-layout row wrap :class="{'px-12 mx-12': $vuetify.breakpoint.smAndUp}">
                    <v-flex sm6 xs12>
                      <v-layout align-center style="height:100%">
                        <v-img class="blue-overlay" :src="strategy" :lazy-src="strategy"/>
                      </v-layout>
                    </v-flex>
                    <v-flex sm6 xs12 class="white-background">
                      <div :class="{'ma-12': $vuetify.breakpoint.smAndUp, 'ma-6': $vuetify.breakpoint.xsOnly}">
                        <p class="venera font-19 primary-color">03</p>
                        <h3 class="antenna font-28 third-color my-4"> {{ $ml.get('strategy_title') }} </h3>
                        <p class="avenir font-16 primary-color">{{ $ml.get('strategy_subtitle') }} -</p>
                        <p class="avenir font-16 primary-color">{{ $ml.get('strategy_text') }}</p>
                      </div>
                    </v-flex>
                  </v-layout>
                  </div>

                <div class="white-background">
                  <div class="max-width-1200 ma-auto">
                  <v-layout row wrap :class="{'px-12 mx-12': $vuetify.breakpoint.smAndUp, 'reverse-class': $vuetify.breakpoint.xsOnly}">
                      <v-flex sm6 xs12 :class="{'clear-background': $vuetify.breakpoint.smAndUp, 'white-background': $vuetify.breakpoint.xsOnly}">
                      <div :class="{'ma-12': $vuetify.breakpoint.smAndUp, 'ma-6': $vuetify.breakpoint.xsOnly}">
                        <p class="venera font-19 primary-color">04</p>
                        <h3 class="antenna font-28 third-color my-4"> {{ $ml.get('partnership_title') }} </h3>
                        <p class="avenir font-16 primary-color">{{ $ml.get('partnership_subtitle') }} -</p>
                        <p class="avenir font-16 primary-color">{{ $ml.get('partnership_text') }}</p>
                      </div>
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-layout align-center style="height:100%">
                        <v-img class="blue-overlay" :src="partnership" :lazy-src="partnership"/>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  </div>
                  </div>


                   
              
              </div>
                
                <v-flex sm10 offset-sm-1 py-12>
                  <div class="max-width-1200 ma-auto">
                    <v-layout pa-4>
                        <v-flex sm6></v-flex>
                        <v-flex sm6 text-right>
                            <p class="venera font-16 primary-color mb-0">{{$ml.get('since')}} 2010</p>
                            <p class="antenna font-34 primary-color"> {{ $ml.get('departments') }} </p>
                        </v-flex>
                    </v-layout>
                    
                    <v-layout row wrap pa-4>
                        <v-layout row wrap>
                        <v-flex xs12 md4 v-for="(area, i) in areas" :key="i" child-flex :class="{'pa-3': $vuetify.breakpoint.smAndUp}">
                                
                                <v-hover>
                                  <div slot-scope="{ hover }">
                                  <div class="image-container">
                                    <v-img :src="area.photo" :lazy-src="area.photo" class="image" :class="{'blue-overlay2': !hover}" width="100%" style="height:250px;">
                                        <div v-if="!hover" class="centered"><p class="venera-bold font-19 white--text">{{$ml.get(area.title)}}</p></div>
                                        <v-expand-transition>
                                        <v-flex v-if="hover" class="transition-fast-in-fast-out hover-img  v-card--reveal" style="height: 90%; margin:15px">
                                        <v-layout row wrap >
                                            <v-flex ma-10 style="text-align:left">
                                                <h3 class="venera-bold font-19 white--text mb-4" > {{$ml.get(area.subtitle)}} </h3>
                                                <p class="avenir font-16 white--text">{{$ml.get(area.text)}}</p>         
                                            </v-flex>                 
                                        </v-layout>
                                        </v-flex>
                                        </v-expand-transition>
                                    </v-img>
                                  </div>
                                  </div>
                              </v-hover>
                        </v-flex>
                        </v-layout>
                    </v-layout>
                  </div>
                </v-flex>

     </v-container>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      employees: require('../assets/img/images/employees.jpg'),
      areas: [
        {
          photo: require('../assets/img/images/commercial-strategies.png'),
          title: 'commercial_title',
          subtitle: 'commercial_title',
          text: 'commercial_text'
        },
        {
          photo: require('../assets/img/images/corporate-services.png'),
          title: 'corporate_title',
          subtitle: 'corporate_title',
          text: 'corporate_text'
        },
        {
          photo: require('../assets/img/images/business-solutions.png'),
          title: 'business_title',
          subtitle: 'business_title',
          text: 'business_text'
        },
        {
          photo: require('../assets/img/images/software-development.png'),
          title: 'development_title',
          subtitle: 'development_title',
          text: 'development_text'
        },
        {
          photo: require('../assets/img/images/marketing-and-branding.png'),
          title: 'marketing_title',
          subtitle: 'marketing_title',
          text: 'marketing_text'
        },
        {
          photo: require('../assets/img/images/growth-and-innovation.png'),
          title: 'innovation_title',
          subtitle: 'innovation_title',
          text: 'innovation_text'
        },
        
      ],
      diagram: require('../assets/img/images/junta.png'),
      number: require('../assets/img/images/number.png'),
      evaluate: require('../assets/img/images/evaluate-or-assess-synergies.png'),
      collaboration: require('../assets/img/images/collaboration.png'),
      strategy: require('../assets/img/images/bespoke-strategy.png'),
      partnership: require('../assets/img/images/partnership-with-shared-services.png'),
           years: [
        {
          color: 'cyan',
          year: '1960',
        },
        {
          color: 'green',
          year: '1970',
        },
        {
          color: 'pink',
          year: '1980',
        },
        {
          color: 'amber',
          year: '1990',
        }
      ],
    }
  }
}
</script>

<style>
.v-timeline:before {
    bottom: 0;
    content: "";
    height: 75%;
    position: absolute;
    top: 0;
    width: 2px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.hover-img {
  background-color: #029599;
  opacity: 1;
  color: #454545;
  align-items: center;
}
.reverse-class {
  flex-direction: column-reverse
}

.blue-overlay {
    height: 350px;
    outline: 175px solid rgba(26,60,79,0.4) !important;
    outline-offset: -175px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}

.blue-overlay2 {
    outline: 125px solid rgba(26,60,79,0.4) !important;
    outline-offset: -125px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}

@media screen and (max-width: 600px) {
  .blue-overlay {
    height: 300px;
    outline: 150px solid rgba(26,60,79,0.4) !important;
    outline-offset: -150px !important;
  }
}
</style>
